<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'

export default {
  components: {
    formEdit
  },
  data() {
    return {
      categories: null,
      users: null,
      validationErrors: [],
      title: "Thêm thời gian lặp lại",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: ''
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editOption' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa thời gian lặp lại' : this.title;
    
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    // await this.userLogin();
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_OPTIONS);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-options' });
    },
    async saveData(params) {
      // console.log("params",params)
      var that = this;
      var objects = {
        name : params.name,
        plain_name : this.$commonFuction.SkipVN(params.name),
        value : params.value,
        active : params.active,
      }
      if(this.mode == 'add'){
        await this.$apollo.mutate({
          mutation: DataServices.insertData('option_repeat'),
          variables: {
            'objects': objects
          }
        }).then(async (res) => {
          if (res.data[Object.keys(res.data)].affected_rows) {
            // var returning = res.data[Object.keys(res.data)].returning[0];
            that.$commonFuction.is_loading(false);
            that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
            that.$router.push({path: '/admin/list-options'});
          }
          
        }).catch(async error => {
          console.log('error', error);
          that.$toast.add({
            severity: 'error',
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000
          });
          this.$commonFuction.is_loading(false);
        })
      }else{
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
          mutation: DataServices.updateData('option_repeat'),
          variables: variables
        }).then(async (res) => {
          console.log(res)
          // var returning = {id : this.models.id}
          that.redirectEdit();
        })
      }
    },

    async uploadImages(storeID) {
      var s3client = Config.s3Client('store')
      if (this.images && storeID > 0) {
        this.$store.commit('setLoading', true);
        var objects = [];
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img
          if (file !== '' && img.id === undefined) {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`
            await s3client.uploadFile(file, fileName).then((data) => {
              objects.push({name: fileName, src: data.location, store_id: storeID, deleted: false});
            });
          }
        }
        if (objects.length > 0) {
          this.$apollo.mutate({
            mutation: DataServices.insertData('store_gallery'),
            variables: {
              objects: objects
            }
          })
        }
      }
      this.$toast.add({ severity: 'success', summary: 'Thông báo', detail: 'Lưu dữ liệu thành công', life: 3000 })
      this.backToList();
    },
    async userLogin() {
      var user = this.$store.getters.user;
      var {data} = await this.$apollo.query({
        query: DataServices.getList('accounts', {"fields" : "uid"}),
        variables: {
          where_key: {
            id: {'_eq': user}
          }
        },
        fetchPolicy: "network-only"
      });
      if (data && data[Object.keys(data)] !== undefined && data[Object.keys(data)].length > 0) {
        this.accountID = data[Object.keys(data)][0].uid;
      }
    },
    async redirectEdit() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-options'};
      // if (!that.$commonFuction.isEmpty(router_back)){
      //   // that.page_transaction = ""+that.page_transaction
      //   router_back.query = { page_transaction: that.page_transaction.toString() };
      // }
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async getData(id) {
      // console.log("dasdasd");
      var {data} = await this.$apollo.query({
        query: DataServices.getList('option_repeat', {"fields" : "id name value active"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      // console.log("this.models",this.models)
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:450px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 5px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.p-field {
  margin-bottom: 0.5rem;
}
</style>